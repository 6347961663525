import './style.scss';
import { ReactComponent as Logo } from '@fava/Asset/Icon/Layout/logo.svg';
import useAccount from '@fava/Hooks/useAccount';
import useResetSession from '@fava/Hooks/useResetSession';
import PublicLayout from '@fava/Layout/Public';
import {
  getUserAdminInfo,
  getUserAdminStructure,
  getUserStudent,
  getUserTeacher,
} from '@fava/Store/Thunks/fetchAccount';
import { routeAdminPrefix, routeStudentPrefix } from '@fava/Utils';
import { LocalStorageUtils } from '@fava/Utils/localStorage';
import configRole from '@fava/Utils/role';
import { faEye, faEyeSlash, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { login } from '../../../Services/user';

const Login: FC = () => {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const account = useAccount();
  const dispatch = useDispatch();
  useResetSession();
  const { push } = useHistory();
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    shouldUseNativeValidation: false,
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const passwordRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (passwordRef.current && passwordRef.current.value) {
      setValue('password', passwordRef.current.value);
    }
  }, [setValue]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data: any) => {
    setIsLoading(true);

    try {
      const response = await login({ ...data, identifier: data.email });

      LocalStorageUtils.setItem('user_infos', JSON.stringify(response?.data?.user));
      LocalStorageUtils.setItem('user_token', response?.data?.jwt);

      const role = configRole[response?.data?.user.role.name];
      account.setData(response?.data?.user);
      account.setType(role || '');

      switch (response?.data?.user?.role?.id) {
        case 1: // Student
          push(`/${routeStudentPrefix}/programs`);
          dispatch(getUserStudent(response?.data?.user?.id) as any);
          window.location.reload();
          break;
        case 4: // EstablishmentAdmin
          dispatch(getUserAdminInfo(response?.data?.user?.id) as any);
          push(`/${routeAdminPrefix}/account`);
          break;
        case 6: // Professor
          dispatch(getUserTeacher(response?.data?.user?.id) as any);
          push(`/teacher/account`);
          break;
        case 3: // StructureAdmin
          dispatch(getUserAdminStructure(response?.data?.user?.id) as any);
          push(`/admin-structure/account`);
          break;
        default:
          console.log(`Unhandled role ID: ${response?.data?.user?.role?.id}`);
      }
    } catch (err: any) {
      if (err?.response?.data?.error?.name === 'ValidationError') {
        if (err?.response?.data?.error?.details?.code === 'missing_license') {
          setError("Votre établissement n'a pas encore de licence");
          return;
        }
        setError('Les informations saisies ne correspondent pas');
      } else {
        setError('Une erreur est survenue');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PublicLayout>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          position: 'fixed',
          top: '25vh',
          minWidth: '33vw',
        }}>
        <div className="text-center">
          <Logo className="d-inline-block" />
          <p className="text-white-connection" style={{ marginTop: 30 }}>
            Connexion
          </p>
        </div>
        <Form.Group controlId="formEmail" className="form-group-mg-top">
          <div className="label-container">
            <Form.Label className="text-white">Adresse mail ou pseudo</Form.Label>
            {errors.email && (
              <FontAwesomeIcon
                color="#FF7E7E"
                icon={faTriangleExclamation}
                className="alert-icon"
              />
            )}
          </div>
          <Form.Control
            type="text"
            className="rounded-input"
            autoComplete="email"
            {...register('email', { required: 'Le champ est obligatoire' })}
          />
          {errors.email && (
            <Form.Text className="text-danger">{errors.email.message as string}</Form.Text>
          )}
        </Form.Group>

        <Form.Group controlId="formPassword" className="form-group-mg-top">
          <div className="label-container">
            <Form.Label className="text-white">Mot de passe</Form.Label>
            {errors.password && (
              <FontAwesomeIcon
                color="#FF7E7E"
                icon={faTriangleExclamation}
                className="alert-icon"
              />
            )}
          </div>
          <div className="password-input">
            <Form.Control
              ref={el => {
                passwordRef.current = el;
                register('password', { required: 'Le champ est obligatoire' }).ref(el);
              }}
              className="rounded-input"
              type={showPassword ? 'text' : 'password'}
              autoComplete="current-password"
              onChange={e => setValue('password', e.target.value.replace(/\s+/g, ''))}
            />
            <FontAwesomeIcon
              icon={showPassword ? faEyeSlash : faEye}
              className="password-icon"
              color="#004F38"
              onClick={togglePasswordVisibility}
            />
          </div>
          {errors.password && (
            <Form.Text className="text-danger">{errors.password.message as string}</Form.Text>
          )}
          <div className="password-forgot-container">
            <a href="/forgot-password" className="redirect-link">
              Mot de passe oublié
            </a>
          </div>
        </Form.Group>
        {!!error?.trim()?.length && <p className="text-danger">{error}</p>}
        <Button className="w-100 form-group-mg-top submit-btn" variant="primary" type="submit">
          {isLoading ? 'Connexion...' : 'Se connecter'}
        </Button>
      </Form>
    </PublicLayout>
  );
};

export default Login;
